.oldsite_content {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 1rem;
    padding: 0 15rem;
}

.oldsite_content img {
    width: 100%;
    filter: grayscale(100%);
}

.oldsite_content img:hover {
    width: 100%;
    filter: grayscale(0%);
}

@media screen and (max-width: 992px) {
    .oldsite_content {
        grid-template-columns: repeat(1, 4fr);
        padding: auto ;
    }
  }
  
  @media screen and (max-width: 768px) {
    .oldsite_content {
        padding: 0 ;
    }

    .oldsite_content img {
        width: 100%;
        filter: grayscale(0%);
    }
  }